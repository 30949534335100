import {
    Route,
    BrowserRouter as Router,
    Routes,
    Navigate,
} from "react-router-dom";
import App from "./App.tsx";
import Logowanie from "./Logowanie.tsx";
import React from "react";

export default function Aplikacja() {
    const state = localStorage.getItem("token")
		? localStorage.getItem("token")
		: false;
	return (
		<Router>
			<Routes>
				<Route path="/" element={state ? <App /> : <Navigate to="/zaloguj" />} />
				<Route path="/zaloguj" element={state ? <Navigate to="/" /> : <Logowanie />} />
			</Routes>
		</Router>
	);
}