import { useEffect, useState } from "react";
import React from "react";
import Axios from "axios";
//import { FaPlayCircle, FaPauseCircle, FaLoop } from "react-icons/fa";
/*
TODO:
- wlasne controlki
	-- controls={false}
	- wlasny div manipulujacy document.getElementById("player")   <--- kurwa badz pewny ze filmik jest zaladowany wpierw bo error i all zepsute inaczej

- localstorage z timestampami

- tagowanie filmow i filtrowanie po tagach
	- onContextMenu:
		- oznacz jako obejrzane/nieobejrzane
		- dodaj/usun ulubione
		- dodaj/usun tag
	- header: dostepne tagi + all,
		dostepne.filmiki.find(film => {
			var zaznaczoneTagi = [...tagi].length;
			tagi.map(tag => {
				if(tag in film.tagi) zaznaczoneTagi--;
			})
			if(zaznaczoneTagi <= 0) return true;
		}) ? wyswietl filmik : "";

- wklej link i pobierz
	- frontend input, axios && sprawdz json stan pobieranych
	- backend odbierz url, sprawdz url, exec wget && dodaj do json pobieranych
	-- frontend jesli stan pobieranego ukonczony, daj info o rozmiarze MB
		-- jesli MB duze frontend axios -> backend exec ffmpeg opt
		-- jesli MB male frontend axios -> backend exec miniaturka.sh && mv gotowe/
*/

interface videoData {
	id: number,
	nazwa: string,
	czasTrwania: number
}

interface filter {
	categories: number[],
	maxLength: number,
	minLength: number,
	name: string
}

interface category {
	id: number,
	name: string
}

interface availableData {
	categories: category[],
	videos: videoData[],
	videoCategories: videoCategories[],
	checked: boolean
}

interface videoCategories {
	video: number,
	category: number
}

export default function App() {
	const [playing, setPlaying] = useState({ video: "", loop: false });
	const [sesja, setSesja] = useState(false);
	const [filter, setFilter] = useState<filter>();
	const [available, setAvailable] = useState<availableData>();

	const checkSession = async () => {
		await Axios.post("https://pop.rzak.eu:3001/session/"+localStorage.getItem("token")).then((r) => {
			if(!r.data['odp']){
				localStorage.clear();
				window.location.href = "/zaloguj";
			} else {
				setSesja(true);
			}
		}).catch((er) => {
			localStorage.clear();
			window.location.href = "/zaloguj";
		});
	}

	const formatTime = (s: number) => {
		let hh: string = (Number(s)/3600).toFixed(0);
		if(Number(hh) > 0 && Number(hh) < 10) hh = '0'+hh;
		let mm: string = (Number(s%3600)/60).toFixed(0);
		let ss: string = ((s%3600)%60).toFixed(0);
		if(Number(ss) < 10) ss = '0'+ss;
		if(Number(hh) > 0){
			if(Number(mm) < 10) mm = '0'+mm;
			return `${hh}:${mm}:${ss}`;
		} else {
			return `${mm}:${ss}`;
		}
	};

	const getVideos = async () => {
		await Axios.get("https://pop.rzak.eu:3001/test").then((res) => {
			const formattedVideoCat: videoCategories[] = res.data['videoCategories'].map((v: {filmikId: number, katId: number}) => ({video: v.filmikId, category: v.katId}));
			const formattedCat: category[] = res.data['categories'].map((c: {id: number, nazwa: string}) => ({id: c.id, name: c.nazwa}));
			setAvailable({videos: res.data["filmiki"], categories: formattedCat, videoCategories: formattedVideoCat, checked: true });
			!localStorage.knownVideos && (localStorage.knownVideos = JSON.stringify(res.data["filmiki"]));
		});
	};

	useEffect( () => {
		if(!sesja) checkSession();
		if(sesja && !available?.videos.length && !available?.checked) getVideos();
	}, [available, sesja])

	const returnVideos = () => {
		const newVids = available?.videos.length! - JSON.parse(localStorage.knownVideos).length;
		return (
			<>
				<h1>{available?.videos.length} videos {newVids > 0 && <sup>({newVids} new)</sup>}</h1>
				<div className="videos">
					{available?.videos.map((video: videoData) => {
						if(filter?.name && !video.nazwa.toLowerCase().includes(filter.name.toLowerCase())) return;
						if(filter?.maxLength && (video.czasTrwania > filter.maxLength)) return;
						if(filter?.minLength && (video.czasTrwania < filter.minLength)) return;
						const url = "https://pop.rzak.eu/filmik/" + video.nazwa + ".mp4";
						const preview = 'url("' + url + '_preview.jpg")';
						return (
							<div
								className={JSON.parse(localStorage.knownVideos).find((v) => v === video) ? "video" : "video newVideo"}
								key={video.id}
								onClick={() => playVideo(url)}
								onContextMenu={(e) => e.preventDefault()}
							>
								<div className="videoPreview" style={{ backgroundImage: preview }} />
								<div className="videoTitle">{video.nazwa}</div>
								<div className="videoDuration">{formatTime(video.czasTrwania)}</div>
							</div>
						);
					})}
				</div>
			</>
		);
	};

	const playVideo = (video: string) => {
		const name = video.replace("https://pop.rzak.eu/filmik/", "").replace(".mp4", "");
		const knownVideos = JSON.parse(localStorage.knownVideos);
		if (!(name in knownVideos)) { knownVideos.push(name); localStorage.knownVideos = JSON.stringify(knownVideos); }
		setPlaying({ ...playing, video: video });
	};

	const returnFilters = () => {
		return(
			<div className="filters">
				Search
				<input type="text" value={filter?.name ? filter.name : ""} onChange={(e) => setFilter({...filter!, name: e.target.value})} />
				Min length
				<input type="number" />
				Max length
				<input type="number" />
				Categories
				<div className="selectedCategories">
					<div className="selectedContainer">
						wybrane
					</div>
					<div className="">
						{available?.categories.map(c => {
							return(<p key={`${c.name+c.id}`}>{c.id} {c.name}</p>)
						}) }
					</div>
				</div>
			</div>
		)
	};

	return (
		<>
			{ available?.categories.length && returnFilters() }
			{ (available?.videos ? returnVideos() : <span>No available videos!</span>)}
			{playing.video && (
				<div className="layerVideo">
					<div className="layerOptions">
						<div className="layerExit" onClick={() => setPlaying({ ...playing, video: "" })}>EXIT</div>
						<div className={playing.loop ? "layerLoop active" : "layerLoop"} onClick={() => setPlaying({ ...playing, loop: !playing.loop })}>LOOP</div>
					</div>
					<video
						id="player"
						controls
						src={playing.video}
						loop={playing.loop}
						onContextMenu={(e) => e.preventDefault()}
					/>
				</div>
			)}
		</>
	);
}
