import Axios from "axios";
import { useState, useEffect } from "react";
import React from "react";
interface daneLogowania {
	haslo: string,
	blokada: boolean,
	blad: string
};

export default function Logowanie(props){
    const state = localStorage.getItem("token")
		? localStorage.getItem("token")
		: false;
    const [dane, setDane] = useState<daneLogowania>({haslo: "", blokada: false, blad: ""});

    const zaloguj = async () => {
        if(!dane.haslo){
            setDane({...dane, blokada: true, blad: "Provide a correct password..."});
            return;
        }
        if(dane.haslo.length < 4){
            setDane({...dane, blokada: true, blad: "Password is too short..."});
            return;
        }
        if(dane.haslo.length > 20){
            setDane({...dane, blokada: true, blad: "Password is too long..."});
            return;
        }
        await Axios.post("https://pop.rzak.eu:3001/login", {haslo: dane.haslo})
        .then(r => {
            if(r.data['token']){
                localStorage.setItem("token", r.data['token']);
                window.location.href = "/";
            } else {
                setDane({...dane, blokada: true, blad: "Provide a correct password..."});
            }
        }).catch(er => {
            setDane({...dane, blokada: true, blad: `Error: ${er.message}`});
        });
    };

    return(
	<>
	<div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div className="accessWindow">
            <h1>Enter Access Code</h1>
            {dane.blad && <p>{dane.blad}</p>}
            <div style={{display: "flex", gap: '5px'}}>
                <input type="text" onChange={(e) => setDane({...dane, blad: "", haslo: e.target.value, blokada: false})} />
                <input type="submit" value="Enter" onClick={(e) => {
                    e.preventDefault();
                    zaloguj();
                }}
                disabled={dane.blokada}
                />
            </div>
            <code>If you want to get access to this website, leave a message on discord: sotiio#0</code>
        </div>
    </div>
	</>)
};